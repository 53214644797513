/** @jsx jsx */

import { Flex, jsx } from 'theme-ui'

import denisNekipelov from '../assets/headshots/academic/denis-nekipelov.jpg'
import peterCramton from '../assets/headshots/academic/peter-cramton.jpg'
import routes from '../constants/routes'

export const halo = {
  title: `CartaX academic advisors`,
  route: routes.partnersAcademic,
  root: `../../`,
  description: `We’ve partnered with experts in auction design, economics, and computer science to shape the future of private markets`,
}

export const intro = {
  ...halo,
  title: `Meet the CartaX academic advisors`,
}

const members = {
  members: [
    {
      src: peterCramton,
      name: `Peter Cramton`,
      title: `Professor of Economics at the University of Cologne and the University of Maryland`,
      bio: (
        <div>
          <p>
            Peter Cramton is Professor of Economics at the University of Cologne and the University of Maryland. Since
            1983, he has conducted research on auction theory and practice. This research appears in leading economics
            journals. The focus is the design of auction-based markets. Applications include communications,
            electricity, and financial markets. On the practical side, he is an independent director on the board of the
            Electric Reliability Council of Texas and an advisor and chief economist to several companies. Since 1993,
            he has advised 14 governments and 41 bidders in spectrum auctions. He is a co-inventor of the spectrum
            auction design used in Canada, Australia, and many European countries to auction spectrum. Since 2001, he
            has played a lead role in the design and implementation of electricity and gas auctions in North America,
            South America, and Europe. He has advised on the design of carbon auctions in Europe, Australia, and the
            United Sates, including conducting the world’s first greenhouse-gas auction held in the UK in 2002. He has
            led the development of innovative auctions in new applications, such as auctions for airport slots, wind
            rights, diamonds, medical equipment, and Internet top-level domains. He received his B.S. in Engineering
            from Cornell University in 1980 and his Ph.D. in Business from Stanford University in 1984.
          </p>
        </div>
      ),
    },
    {
      src: denisNekipelov,
      name: `Denis Nekipelov`,
      title: `Associate Professor of Economics and Computer Science at the University of Virginia`,
      bio: (
        <div>
          <p>
            Denis Nekipelov is an Associate Professor of Economics and Computer Science at the University of Virginia
            and a member of the academic advisory board of the School of Data Science. Nekipelov's work is aimed at the
            analysis and theory of scalable and efficient techniques that can be used to estimate the models of
            strategic behavior in large marketplaces. These techniques are developed by combination of statistical
            inference tools available in Econometrics and scalable algorithms coming from Computer Science. In the past
            10 years Nekipelov collaborated with several online search platforms to help design and improve their
            marketplaces. Nekipelov received his B.Sc. and M.Sc. in Applied Physics and Mathematics at Moscow Institute
            of Physics and Technology and his Ph.D. in Economics from Duke University and has been a post-doctoral
            researcher at Microsoft Research - Silicon Valley.
          </p>
        </div>
      ),
    },
  ],
}

export const content = members.members.map(({ src, name, title, bio }, index) => {
  return (
    <Flex key={name} sx={{ py: 5, px: 4, bg: index % 2 !== 0 ? `gray0_slate8` : `transparent` }}>
      <Flex
        sx={{
          maxWidth: `var(--maxContentWidth)`,
          mx: `auto`,
          px: [0, null, null, `24px`],
          flexDirection: [`column`, null, `row`],
          flex: 1,
        }}
      >
        <img
          {...{ src }}
          alt={`Portrait of ${name}`}
          sx={{
            size: [240, null, 200, 240],
            mr: 4,
            borderRadius: 999,
            objectFit: `cover`,
            alignSelf: [`center`, null, `flex-start`],
            color: `avatarShadow`,
            boxShadow: `0 2px 8px -2px`,
          }}
        />
        <div sx={{ maxWidth: `55ch`, mt: [3, null, null, 0] }}>
          <h2 sx={{ my: 0, fontSize: 5 }}>{name}</h2>
          <h3 sx={{ mt: 2, mb: 0 }}>{title}</h3>
          <div sx={{ my: 0 }}>{bio}</div>
        </div>
      </Flex>
    </Flex>
  )
})
